.u-hidden {
    display: none !important;
}

.u-relative {
    position: relative !important;
}

.u-centered {
    text-align: center !important;
}

.u-right {
    text-align: right !important;
}

.u-inline {
    display: inline !important;
    width: auto !important;
}

.u-vertically-centered {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
}

.u-vertically-bottom {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
}

.u-circle {
    overflow: hidden;
    border-radius: 100% !important;
}

.u-visuallyhidden {
    @include visuallyhidden;
}

.u-uppercase {
    text-transform: uppercase;
}

@include breakpoint(medium-screens) {

    .u-inline\@ms {
        display: inline !important;
        width: auto !important;
    }

}

@include breakpoint-up-to(medium-screens) {

    .u-hidden\¬ms {
        display: none !important;
    }

}

@include breakpoint-up-to(large-screens) {

    .u-centered\¬ls {
        text-align: center !important;
    }

}
