.c-table--condensed {

    th,
    td {
        padding: $tablepaddingcondensed;
    }

}

.c-table--bordered {
    border: $tableborderwidth $tableborderstyle $tablebordercolor;

    th,
    td {
        border-left: $tableborderwidth $tableborderstyle $tablebordercolor;
    }

    caption + thead tr:first-child th,
    caption + tbody tr:first-child th,
    caption + tbody tr:first-child td,
    colgroup + thead tr:first-child th,
    colgroup + tbody tr:first-child th,
    colgroup + tbody tr:first-child td,
    thead:first-child tr:first-child th,
    tbody:first-child tr:first-child th,
    tbody:first-child tr:first-child td {
        border-top: 0;
    }

}

.c-table-striped {

    tbody tr:nth-child(odd) td,
    tbody tr:nth-child(odd) th {
        background-color: $tablebackgroundstripe;
    }

}
