.o-island {
    padding: $baselineheight;
}

.o-island-half {
    padding: $baselineheight/2;
}

.o-island-double {
    padding: $baselineheight*2;
}

.o-island-triple {
    padding: $baselineheight*3;
}

@include breakpoint(medium-screens) {

    .o-island-double\@ms {
        padding: $baselineheight*2;
    }

}

@include breakpoint-up-to(large-screens) {

    .o-island\¬ls {
        padding: $baselineheight;
    }

}

@include breakpoint(large-screens) {

    .o-island-double\@ls {
        padding: $baselineheight*2;
    }

    .o-island-triple\@ls {
        padding: $baselineheight*3;
    }

}
