.c-signal {
    position: relative;

    &:after {
        position: absolute;
        left: 50%;
        margin-left: -20px;
        content: "";
        z-index: 100;
    }

}

    .c-signal--top {

        &:after {
            top: -1px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $white;
        }

    }

    .c-signal--bottom {

        &:after {
            bottom: -1px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid $white;
        }

    }

    .c-signal--bottom-left {

        &:after {
            left: 25%;
            bottom: -1px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid $white;
        }

    }

    .c-signal--right-top {

        &:after {
            top: 50%;
            margin-top: -20px;
            left: auto;
            right: -1px;
            border-top: 20px solid transparent;
            border-right: 20px solid $white;
            border-bottom: 20px solid transparent;
        }

    }

    .c-signal--left-top {

        &:after {
            top: 50%;
            margin-top: -20px;
            left: 20px;
            right: auto;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 20px solid $white;
        }

    }
