.c-alert {
    padding: $alertpadding;
    border-radius: $alertborderradius;
    @include font-size($alertfontsize);
    //color: $alertcolor;
    text-align: left;
    font-weight: bold;

    a {
        color: currentColor;
    }

}

    .c-alert--fixed {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
    }

    .c-alert--info {
        color: $alertcolorinfo;
        border-color: $alertbordercolorinfo;
    }

    .c-alert--success {
        color: $alertcolorsuccess;
        border-color: $alertbordercolorsuccess;
    }

    .c-alert--warning {
        color: $alertcolorwarning;
        border-color: $alertbordercolorwarning;
    }

    .c-alert--error {
        color: $alertcolorerror;
        border-color: $alertbordercolorerror;
    }

.c-alert__close {
    cursor: pointer;
    float: right;
    text-decoration: none;
}