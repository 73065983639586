@mixin o-link-list() {
    list-style: none;

    li {
        display: inline-block;
        margin: 0 $baselineheight*2;
    }

    span {
        display: block;
        @include font-size(14);
    }

}

.o-link-list {
    @include o-link-list;
}