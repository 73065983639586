.c-fieldgroup {
    @include zero;
    border: 0;

    p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: $baselineheight/2;
    }

    input,
    textarea {
        //width: 100%;
        flex: 1;
        -webkit-appearance: none;

        ~ button {
            margin-left: $baselineheight/4;
        }

    }

    .c-alert {
        width: 100%;
        order: 3;
    }

}