h1, h2, h3, h4, h5, h6 {
    font-family: $baseheadingfont;
    font-weight: $baseheadingfontweight;
    color: $baseheadingfontcolor;
    text-rendering: optimizelegibility;
    text-transform: uppercase;

    small {
        font-weight: normal;
        line-height: 1;
        color: $lightneutralcolor;
    }

}

h1 { @include hh1; }
h2 { @include hh2; }
h3 { @include hh3; }
h4 { @include hh4; }
h5 { @include hh5; }
h6 { @include hh6; }

.h2 { @include hh2; }
.h3 { @include hh3; }
.h4 { @include hh4; }
.h5 { @include hh5; }

@include breakpoint(medium-screens) {

    .h4\@ms {
        @include hh4;
    }

    div.h4\@ms {
        margin: 0;
    }

}

@include breakpoint-up-to(medium-screens) {

    .h3\¬ms {
        @include hh3;
    }

    .h4\¬ms {
        @include hh4;
    }

}

.lead {
    font-family: $baseheadingfont;
    font-weight: $baseheadingfontweight;
    margin-top: 0;
    margin-bottom: 0;

    &:after {
        display: block;
        content: "";
        height: 1px;
        width: 60px;
        background-color: $black;
        margin-top: $baselineheight;
    }

    .u-centered &:after {
        margin: $baselineheight auto 0 auto;
    }

}

.secondary {
    font-family: $basefontfamily;
    font-weight: 400;
}

@include breakpoint(medium-screens) {

    .huge\@ms {
        margin-top: 0;
        margin-bottom: 0;
        @include font-size(60);
        font-weight: 600;
    }

}

p,
ol,
ul,
dl,
address {
    margin-bottom: $baselineheight;
    //@include font-size($basefontsize);
    //line-height: 1.5;
}

small {
    @include font-size($basefontsize / 1.25);
}

ul,
ol {
    margin: 0 0 $baselineheight #{-$gutterwidth};
    padding: 0 0 0 $gutterwidth;
}

li ul,
li ol {
    list-style-type: none;
    margin: $baselineheight 0;
    //@include font-size($basefontsize);
}

ul.inline,
ol.inline {
    list-style-type: none;
    margin-left: 0;

    & > li {
        display: inline-block;
        padding-left: $gutterwidth / 2;
        padding-right: $gutterwidth / 2;
    }

}

blockquote  {
    font-style: italic;

    small {

        &:before {
            content: '\00A0 \2014';
        }

        &:after {
            content: '';
        }

    }

    cite {
        display: block;
        font-style: italic;
        @include font-size(16);
        margin-top: $baselineheight;
        opacity: .8;

        &:before {
            content: "— ";
        }
    }

    &.caption {
        @include hh4;
        line-height: 1.5;
        font-family: $altfontfamily;

        @include breakpoint(medium-screens) {
            @include hh2;
            line-height: 1.5;
        }

    }

}

q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

dl,
dd {
    margin-bottom: $baselineheight;
}

dt {
    font-weight: bold;
}

abbr[title] {
    border-bottom: 1px dotted $bordercolor;
    cursor: help;
}

b, strong {
    font-weight: 600;
}

dfn {
    font-style: italic;
}

ins {
    background-color: $lightneutralcolor;
    color: $textcolor;
    text-decoration: none;
}

mark {
    background-color: $lightneutralcolor;
    color: $textcolor;
    font-style: italic;
    font-weight: bold;
}

pre,
code,
kbd,
samp {
    font-family: $codefont;
    @include font-size($basefontsize / 1.25);
    line-height: $baselineheight;
    color: lighten($textcolor,.50%);
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

sub,
sup {
    position: relative;
    @include font-size($basefontsize / 1.25);
    line-height: 0;
    vertical-align: baselineheight;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}
