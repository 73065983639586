table {
    @include zero;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: $baselineheight;
    background-color: $tablebackgroundcolor;
    font-family: $tablefontfamily;

    th,
    td {
        padding: $tablepadding;
        vertical-align: middle;
        border-top: $tableborderwidth $tableborderstyle $tablebordercolor;
        @include font-size($basefontsize);
        line-height: $baselineheight;
        text-align: left;
    }

    th {
        font-weight: bold;
    }

    colgroup + thead tr:first-child th,
    colgroup + thead tr:first-child td,
    :first-child tr:first-child th,
    :first-child tr:first-child td {
        border-top: 0;
    }

    tbody + tbody {
        border-top: $tableborderwidth * 2 $tableborderstyle $tablebordercolor;
    }

    tbody tr:hover td,
    tbody tr:hover th {
        background-color: $tablebackgroundhover;
    }

}
