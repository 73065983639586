@-webkit-viewport   { width : device-width; }
@-moz-viewport      { width : device-width; }
@-ms-viewport       { width : device-width; }
@-o-viewport        { width : device-width; }
@viewport           { width : device-width; }

* {
    box-sizing: border-box;
}

html {
    background: $bodycolor;
    //background-image: url(http://basehold.it/i/24);
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: $lightestneutralcolor;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    font-family: $basefontfamily;
    @include font-size(16);
    line-height: 1.5;
    font-weight: $basefontweight;
    color: $textcolor;
}

figure {
    margin: 0;
    line-height: 0;
}

img {
    @include zero;
    max-width: 100%;
    line-height: 0;
}

a {
    color: $linkcolor;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

}

.wf {

    h1,
    h2,
    h3,
    h4 {
        font-size: 14pt
    }

    header[role="banner"],
    footer[role="contentinfo"],
    div {
        margin: 10px;
        padding: 10px;
        border: 1px solid gainsboro;
        background-color: whitesmoke;
        text-align: center;
    }

    header[role="banner"] {
        //height: 5em;
    }

    #intro {
        height: 50vh;
    }

    #cerimonia {
        height: 20em;
    }

    #giorno {
        height: 20em;
    }

    #su-misura {
        height: 20em;
    }

    #news {
        height: 30em;
    }

    #contatti {
        height: 30em;
    }

    footer[role="contentinfo"] {
        //height: 5em
    }

}