.o-pile-list {
    padding-left: 0;
    margin-left: 0;
    line-height: 2;
    list-style: none;

    img {
        max-width: 110px;
        max-height: 70px;
    }

    @include breakpoint(medium-screens) {

        img {
            max-width: 200px;
            //max-height: 90px;
        }

    }

}
