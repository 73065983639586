.c-message {
    @include font-size(12);
}

    .c-message--info {
        color: $infocolor;
    }

    .c-message--success {
        color: $successcolor;
    }

    .c-message--warning {
        color: $warningcolor;
    }

    .c-message--error {
        color: $errorcolor;
    }