.c-header {
    @include clearfix;
    background-color: $black;

    h1 {
        @include zero;
        background: url("../images/logo.svg") center no-repeat;
        background-size: 200px;
        text-indent: -999em;

        a {
            display: block;
        }

    }

    @include breakpoint(medium-screens) {

        > div {
            display: flex;
            align-items: center;
        }

        h1 {
            width: 260px;
            background-size: 260px;
        }

    }

}
