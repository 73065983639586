.t-theme-primary {
    background-color: $black;
    color: $white;

    .lead {

        &:after {
            background-color: $white;
        }

    }

    .c-signal--right-top:after {
        border-right-color: $black;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        background-color: transparent;
        border-color: $white;
        color: $white;
        @include placeholder($white);
    }

}

.t-theme-secondary {
    background-color: lighten($black, 25%);
    color: $white;

    .lead {

        &:after {
            background-color: $white;
        }

    }

    a {
        color: $white;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        background-color: transparent;
        border-color: $white;
        color: $white;
        @include placeholder($white);
    }

    table {

        th,
        td {
            border-top-color: lighten($black, 35%);
        }

        tbody tr:hover td,
        tbody tr:hover th {
            background-color: lighten($black, 30%);
        }

    }

}

.t-theme-tertiary {
    background-color: lighten($black, 95%);
    color: lighten($black, 20%);

    .c-signal--right-top:after, {
        border-right-color: lighten($black, 95%);
    }

    .c-signal--left-top:after, {
        border-left-color: lighten($black, 95%);
    }

    .lead {

        &:after {
            background-color: lighten($black, 20%);
        }

    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        background-color: transparent;
        border-color: $white;
        color: $white;
        @include placeholder($white);
    }

}
