.o-bridge-horizontal {
    padding-top: $baselineheight;
    padding-bottom: $baselineheight;
}

.o-bridge-vertical {
    padding-right: $baselineheight;
    padding-left: $baselineheight;
}

@include breakpoint(medium-screens) {

    .o-bridge-vertical\@ms {
        padding-right: $baselineheight;
        padding-left: $baselineheight;
    }

    .o-bridge-vertical-2x\@ms {
        padding-right: $baselineheight*2;
        padding-left: $baselineheight*2;
    }

    .o-bridge-horizontal-2x\@ms {
        padding-top: $baselineheight*2;
        padding-bottom: $baselineheight*2;
    }

    .o-bridge-horizontal-3x\@ms {
        padding-top: $baselineheight*3;
        padding-bottom: $baselineheight*3;
    }

    .o-bridge-horizontal-4x\@ms {
        padding-top: $baselineheight*4;
        padding-bottom: $baselineheight*4;
    }

}

@include breakpoint(large-screens) {

    .o-bridge-horizontal-4x\@ls {
        padding-top: $baselineheight*4;
        padding-bottom: $baselineheight*4;
    }

}
