.o-grid {
    @include zero;
    @include clearfix;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    > * {
        display: block;
        width: 100%;
    }

}

.o-grid--half {

    > * {
        flex: 0 0 50%;
    }

}

.o-grid--third {

    > * {
        flex: 0 0 33.333%;
    }

}

.o-grid--fourth {

    > * {
        flex: 0 0 25%;
    }

}

.o-grid--fifth {

    > * {
        flex: 0 0 20%;
    }

}

@include breakpoint(medium-screens) {

    .o-grid--half\@ms {

        > * {
            flex: 0 0 50%;
        }

    }

    .o-grid--third\@ms {

        > * {
            flex: 0 0 33.333%;
        }

    }

    .o-grid--fourth\@ms {

        > * {
            flex: 0 0 25%;
        }

    }

    .o-grid--fifth\@ms {

        > * {
            flex: 0 0 20%;
        }

    }

}

@include breakpoint(large-screens) {

    .o-grid--half\@ls {

        > * {
            flex: 0 0 50%;
        }

    }

    .o-grid--third\@ls {

        > * {
            flex: 0 0 33.333%;
        }

    }

    .o-grid--fourth\@ls {

        > * {
            flex: 0 0 25%;
        }

    }

    .o-grid--fifth\@ls {

        > * {
            flex: 0 0 20%;
        }

    }

}

@include breakpoint(xlarge-screens) {

    .o-grid--half\@xls {

        > * {
            flex: 0 0 50%;
        }

    }

    .o-grid--third\@xls {

        > * {
            flex: 0 0 33.333%;
        }

    }

    .o-grid--fourth\@xls {

        > * {
            flex: 0 0 25%;
        }

    }

    .o-grid--fifth\@xls {

        > * {
            flex: 0 0 20%;
        }

    }

}

    .o-grid--stretch {
        align-items: stretch;

        > * {
            margin-right: auto;
            margin-left: auto;
        }
        
    }

    .o-grid--reverse {
        flex-direction: row-reverse;
    }

    .o-grid--guttered {
        margin-left: -$baselineheight/2;
        margin-right: -$baselineheight/2;

        > * {
            padding: 0 $baselineheight/2 0;
        }

    }

    .o-grid--vertically-centered {
        align-items: center;
    }

    .o-grid--horizontally-centered {
        justify-content: center;
    }

.no-flexbox {

    .o-grid > * {
        float: left;
    }

    .o-grid--half,
    [class^="o-grid--half"],
    [class*=" o-grid--half"] {
        > * { width: 50%; }
    }

    .o-grid--third,
    [class^="o-grid--third"],
    [class*=" o-grid--third"] {
        > * { width: 33.333%; }
    }

    .o-grid--fourth,
    [class^="o-grid--fourth"],
    [class*=" o-grid--fourth"] {
        > * { width: 25%; }
    }

    .o-grid--fifth,
    [class^="o-grid--fifth"],
    [class*=" o-grid--fifth"] {
        > * { width: 20%; }
    }

}
