// Media queries
// ==========================================================================

@mixin breakpoint($width) {
    @if $width == xsmall-screens {
        @media only screen and (min-width: $width-xsmall/$basefontsize+em) { @content; }
    }
    @else if $width == small-screens {
        @media only screen and (min-width: $width-small/$basefontsize+em) { @content; }
    }
    @else if $width == medium-screens {
        @media only screen and (min-width: $width-medium/$basefontsize+em) { @content; }
    }
    @else if $width == large-screens {
        @media only screen and (min-width: $width-large/$basefontsize+em) { @content; }
    }
    @else if $width == xlarge-screens {
        @media only screen and (min-width: $width-xlarge/$basefontsize+em) { @content; }
    }
}

@mixin breakpoint-up-to($width) {
    @if $width == xsmall-screens {
        @media only screen and (max-width: ($width-xsmall - 1)/$basefontsize+em) {
            @content;
        }
    } @else if $width == small-screens {
        @media only screen and (max-width: ($width-small - 1)/$basefontsize+em) {
            @content;
        }
    } @else if $width == medium-screens {
        @media only screen and (max-width: ($width-medium - 1)/$basefontsize+em) {
            @content;
        }
    } @else if $width == large-screens {
        @media only screen and (max-width: ($width-large - 1)/$basefontsize+em) {
            @content;
        }
    } @else if $width == xlarge-screens {
        @media only screen and (max-width: ($width-xlarge - 1)/$basefontsize+em) {
            @content;
        }
    }
}

// Typography
// ==========================================================================

@mixin font-size($font-size: $basefontsize){
    font-size: #{$font-size / $basefontsize}rem;
}

@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin hh1 {
    @include font-size(60);
    line-height: 1.2;
    margin: (24/60)*1em 0;
}

@mixin hh2 {
    @include font-size(48);
    line-height: 1;
    margin: (24/48)*1em 0;
}

@mixin hh3 {
    @include font-size(36);
    line-height: 1.35;
    margin: (24/36)*1em 0;
}

@mixin hh4 {
    @include font-size(24);
    line-height: 1.5;
    margin: (24/24)*1em 0;
}

@mixin hh5 {
    @include font-size(16);
    line-height: 1.5;
    margin: (24/16)*1em 0;
}

@mixin hh6 {
    @include font-size(14);
    line-height: 1.75;
    margin: (24/14)*1em 0;
    text-transform: uppercase;
}

// Hiders
// ==========================================================================

// Hide from visual and speaking browsers

@mixin hidden() {
    display: none !important;
    visibility: hidden;
}

// Hide but maintain layout

@mixin invisible() {
    visibility: hidden;
}

// Hidden but available to speaking browsers

@mixin visuallyhidden() {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

// Utility
// ==========================================================================

// Clearfix

@mixin clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }

}

// @include resize(VALUE) (none, both, horizontal, vertical, inherit)

@mixin resize($direction: both) {
    resize: $direction;
    overflow: auto;
}

// @include userselect(VALUE) (all, element, none, text)

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -o-user-select: $select;
    user-select: $select;
}

// @include placeholder(VALUE) (color)

@mixin placeholder($color : $neutralcolor) {

    &:-moz-placeholder {
        @include font-size($basefontsize);
        color: $color;
    }

    &::-moz-placeholder {
        @include font-size($basefontsize);
        color: $color;
    }

    &:-ms-input-placeholder {
        @include font-size($basefontsize);
        color: $color;
    }

    &::-webkit-input-placeholder {
        @include font-size($basefontsize);
        color: $color;
    }

}

// @include zero

@mixin zero {
    margin: 0;
    padding: 0;
}

// @include heading(MARGIN, FONT SIZE, LINE HEIGHT)

@mixin heading($margin : 1, $font-size : 1, $line-height : "")  {

    margin-bottom: $baselineheight / $margin;
    @include font-size($basefontsize * $font-size);
    @if $line-height != "" { line-height: $line-height }

}
