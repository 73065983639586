.c-button {
    display: inline-block;
    padding: $buttonpadding;
    margin-bottom: 0;
    font-weight: 400;
    font-family: $buttonfontfamily;
    @include font-size($buttonfontsize);
    line-height: $baselineheight;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    background-color: $buttonbackgroundcolor;
    color: $buttoncolor;
    border: $buttonborderwidth $buttonborderstyle $buttonbordercolor;
    border-bottom-color: $buttonbottombordercolor;
    border-radius: $buttonborderradius;
    cursor: pointer;

    &:visited,
    &:hover,
    &:active {
    color: darken($buttoncolor, 20%);
    background-color: darken($buttonbackgroundcolor, 5%);
    text-decoration: none; }

    &.is-disabled {
        opacity: .5;
        cursor: default;
        background-color: $buttonbackgroundcolor;
    }

}

    .c-button--primary {
        color: $buttonprimarycolor;
        background-color: $buttonprimarybackgroundcolor;

        &:visited,
        &:active {
            color: $buttonprimarycolor;
        }

        &:hover {
            color: lighten($buttonprimarycolor, 100%);
            background-color: $black;
        }
    }

    .c-button--secondary {
        color: $buttonsecondarycolor;
        background-color: $buttonsecondarybackgroundcolor;
        border-color: $white;

        &:visited,
        &:active {
            color: $buttonsecondarycolor;
        }

        &:hover {
            color: $black;
            background-color: $white;
        }

    }

    .c-button--tertiary {
        color: $buttontertiarycolor;
        background-color: $buttontertiarybackgroundcolor;

        &:visited,
        &:hover,
        &:active {
            color: $white;
            background-color: darken($buttontertiarybackgroundcolor, 10%);
            border-color: darken($buttontertiarybackgroundcolor, 12%);
        }
    }

    .c-button--large {
        @include font-size(21);
    }

    .c-button--small {
        @include font-size(11);
    }
