// numero di slide
$slides: 4;

// tempo per slide
$time_per_slide: 9;

// tempo totale dell'animazione
$total_animation_time: $time_per_slide * $slides;


@keyframes round{

    25%{
        opacity:1;
    }

    40%{
        opacity:0;
    }

}

.c-slider {
    position: relative;
    //width: 1920px;
    //min-height: 1080px;
    overflow: hidden;

    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        animation:round #{$total_animation_time}s infinite;
        opacity: 0;
    }

    img:first-child {
        position: relative
    }

    @include breakpoint-up-to(medium-screens) {

        &.c-signal--bottom {

            &:after {
                border-bottom-color: $black;
            }

        }

    }

}

    .c-slider__controls {
        @include zero;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 40;

        li {
            display: inline-block;
            margin: 0 $baselineheight/4;
        }

        a {
            display: block;
            width: .65em;
            height: .65em;
            background-color: $white;
            border: 2px solid transparent;
            text-indent: -999em;
            border-radius: 50%;
            opacity: .4;

            &:hover {
                opacity: 1;
            }

        }

        .is-active a {
            opacity: 1;
        }

    }

    @for $index from 1 to $slides + 1{

        .c-slider img:nth-child(#{$index}){
            animation-delay:#{$total_animation_time - $time_per_slide * $index}s
        }

    }
