.c-nav {
    background-color: lighten($black, 10%);

    ul {
        @include zero;
        display: flex;
        list-style: none;
        @include font-size(10);
        text-transform: uppercase;
        font-family: $baseheadingfont;
        font-weight: 500;
    }

    li {
        flex: 1;
        text-align: center;
    }

    a {
        display: block;
        height: 100%;
        padding: $baselineheight 0;
        color: $white;

        &:hover {
            text-decoration: none;
            background-color: lighten($black, 10%);
        }

        img {
            max-width: 16px;
        }

    }

    .is-active a {
        text-decoration: none;
        background-color: lighten($black, 10%);
    }

    @include breakpoint(medium-screens) {
        margin-left: auto;
        width: 60%;
        background-color: transparent;

        ul {
            @include font-size(12);
            letter-spacing: 1px;
        }

        a {
            padding: $baselineheight*3 $baselineheight/2;
        }

    }

}

    .c-nav__highlighted {

        a {
            padding-right: 0;
            padding-left: 0;
            //background-color: lighten($black, 15%);
        }

    }

    .c-nav__social-facebook {

        a:hover {
            background-color: #3b5998;
        }

    }

    .c-nav__social-instagram {

        a:hover {
            background-color: #bc2a8d;
        }

    }
