.c-tooltip {
    @include font-size($tooltipfontsize);
    position: relative;
}

.c-tooltip__toggler {
    cursor: pointer;

    &:hover + .c-tooltip__content {
        display: block;
    }

}

.c-tooltip__content {
    display: none;
    position: absolute;
    top: 100%;
    margin-top: $tooltiparrowsize;
    left: -$tooltipminwidth/2;
    z-index: 100;
    min-width: $tooltipminwidth;
    padding: $tooltippadding;
    border-radius: $tooltipborderradius;
    font-style: normal;
    background-color: $tooltipbackground;
    color: $tooltipcolor;

    &:before {
        position: absolute;
        top: -$tooltiparrowsize;
        left: 50%;
        margin-left: -$tooltiparrowsize/2;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 $tooltiparrowsize $tooltiparrowsize $tooltiparrowsize;
        border-color: transparent transparent $tooltipbackground transparent;
    }
}