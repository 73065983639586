.c-overlay {
    background-color: $black;
    color: $white;

    @include breakpoint(small-screens) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 30;
        min-width: 33.333%;
        padding-top: 25%;
        background-color: rgba($black, .4);
    }

}
