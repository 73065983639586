.c-badge {
    display: inline-block;
    padding: $badgepadding;
    border-radius: $badgeborderradius;
    @include font-size($badgefontsize);
    background-color: $badgebackground;
    color: lime;
    color: $white;
}

.c-badge--info {
    background-color: $badgebackgroundinfo;
}

.c-badge--success {
    background-color: $badgebackgroundsuccess;
}

.c-badge--warning {
    background-color: $badgebackgroundwarning;
}

.c-badge--error {
    background-color: $badgebackgrounderror;
}
