$bodycolor                      : rgb(255,255,255);
$textcolor                      : rgb(71,63,63);
$gutterwidth                    : 24px;
$baselineheight                 : 1.5em;

// Media Queries
// ==========================================================================

$width-xsmall                   : 480;
$width-small                    : 600;
$width-medium                   : 768;
$width-large                    : 992;
$width-xlarge                   : 1382;

$maxwidth                       : 70em;


// Typography
// ==========================================================================

$basefontfamily                 : "jaf-bernino-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$basefontsize                   : 16;
$basefontweight                 : 300;
$baseheadingfont                : "freight-sans-pro";
$baseheadingfontweight          : 600;
$baseheadingfontcolor           : inherit;
$altfontfamily                  : Palatino, "Times New Roman", Times, serif;
$altlineheight                  : 1.35em;
$ampfontfamily                  : local('Georgia'), local('Garamond'), local('Palatino'), local('Book Antiqua');
$codefont                       : Monaco, Courier New, monospace;


// Colour
// ==========================================================================

$primarycolor                   : #000000;
$secondarycolor                 : #466eaa;
$tertiarycolor                  : #ff5050;

$neutralcolor                   : rgb(71,63,63);
$midneutralcolor                : darken($neutralcolor,25%);
$darkneutralcolor               : darken($neutralcolor,50%);
$lightneutralcolor              : lighten($neutralcolor,25%);
$lighterneutralcolor            : lighten($neutralcolor,50%);
$lightestneutralcolor           : lighten($neutralcolor,70%);

$black                          : rgb(0,0,0);
$white                          : rgb(255,255,255);

$linkcolor                      : $primarycolor;
$linkcolorhover                 : darken($linkcolor, 10);
$linkcolorvisited               : darken($linkcolorhover, 10);
$linkcolorfocus                 : darken($linkcolorvisited, 10);

$alertcolor                     : rgb(252,248,227);
$errorcolor                     : rgb(218,79,73);
$infocolor                      : rgb(152,190,222);
$inversecolor                   : rgb(65,65,65);
$successcolor                   : rgb(150,197,111);
$warningcolor                   : rgb(255,175,75);

// Textures
// ==========================================================================

$bordercolor                    : $lighterneutralcolor;
$borderstyle                    : solid;
$borderwidth                    : 1px;
$borderradius                   : 0;

// Alerts
// ==========================================================================

$alertpadding                   : $baselineheight/4 0;
$alertbackgroundcolor           : rgb(245,239,191);
$alertborderwidth               : $borderwidth * 2;
$alertborderstyle               : $borderstyle;
$alertbordercolor               : darken($alertbackgroundcolor, 20%);
$alertborderradius              : $borderradius;
$alertfontsize                  : $basefontsize;

//$alertcolor                     : darken($alertbackgroundcolor, 75%);

$alertshadow                    : darken($alertbackgroundcolor, 10%);
$alertshadowblur                : 10px;

// Error
$alertbordercolorerror          : darken($errorcolor, 10%);
$alertcolorerror                : $errorcolor;

// Info
$alertbordercolorinfo           : darken($infocolor, 10%);
$alertcolorinfo                 : darken($infocolor, 50%);

// Success
$alertbordercolorsuccess        : darken($successcolor, 10%);
$alertcolorsuccess              : $successcolor;

// Warning
$alertbordercolorwarning        : lighten($warningcolor, 20%);
$alertcolorwarning              : darken($warningcolor, 20%);

// Badges
// ==========================================================================

$badgepadding                   : 0 $gutterwidth/3 0;
$badgeborderradius              : 100px;
$badgefontsize                  : $basefontsize/1.15;
$badgebackground                : $lighterneutralcolor;
$badgecolor                     : darken($lighterneutralcolor, 30%);

// Error
$badgebackgrounderror           : $errorcolor;
$badgecolorerror                : darken($errorcolor, 30%);

// Info
$badgebackgroundinfo            : $infocolor;
$badgecolorinfo                 : darken($infocolor, 30%);

// Success
$badgebackgroundsuccess         : $successcolor;
$badgecolorsuccess              : darken($successcolor, 30%);

// Warning
$badgebackgroundwarning         : $warningcolor;
$badgecolorwarning              : darken($warningcolor, 30%);

// Tooltip
// ==========================================================================

$tooltipminwidth                  : 300px;
$tooltippadding                   : $baselineheight/2 $baselineheight;
$tooltipborderradius              : 2px;
$tooltipfontsize                  : 12;
$tooltipbackground                : $secondarycolor;
$tooltipcolor                     : $white;
$tooltiparrowsize                 : 10px;

// Buttons
// ==========================================================================

$buttonpadding                  : $baselineheight/4 $baselineheight;
$buttonbackgroundcolor          : $lightestneutralcolor;
$buttonprimarybackgroundcolor   : transparent;
$buttonsecondarybackgroundcolor : transparent;
$buttontertiarybackgroundcolor  : $tertiarycolor;
$buttonprimarycolor             : $black;
$buttonsecondarycolor           : $white;
$buttontertiarycolor            : $black;
$buttonborderwidth              : 1px;
$buttonborderstyle              : $borderstyle;
$buttonbordercolor              : $black;
$buttonbottombordercolor        : darken($buttonbordercolor, 5%);
$buttonborderradius             : $borderradius;
$buttonfontfamily               : $basefontfamily;
$buttonfontsize                 : $basefontsize;
$buttoncolor                    : $lightneutralcolor;
$buttonbordercolorprimary       : darken($primarycolor, 10%);
$buttoncolorprimary             : $white;

// Error
$buttonbordercolorerror         : darken($errorcolor, 10%);
$buttoncolorerror               : $white;

// Info
$buttonbordercolorinfo          : darken($infocolor, 10%);
$buttoncolorinfo                : darken($infocolor, 50%);

// Success
$buttonbordercolorsuccess       : darken($successcolor, 10%);
$buttoncolorsuccess             : darken($successcolor, 50%);

// Warning
$buttonbordercolorwarning       : darken($warningcolor, 10%);
$buttoncolorwarning             : $white;

// Tables
// ==========================================================================

$tablepadding                   : $baselineheight/2;
$tablepaddingcondensed          : $baselineheight/6 $baselineheight/4;
$tablebackgroundcolor           : transparent;
$tablebackgroundstripe          : $lightestneutralcolor;
$tablebackgroundhover           : $lightestneutralcolor;
$tableborderwidth               : $borderwidth;
$tableborderstyle               : $borderstyle;
$tablebordercolor               : lighten($black, 94%);
$tablefontfamily                : $basefontfamily;

// Forms
// ==========================================================================

$forminputpadding               : $baselineheight/4;
$formbinputbackgroundcolor      : $white;
$forminputborderwidth           : $borderwidth;
$forminputborderstyle           : $borderstyle;
$forminputbordercolor           : lighten($black, 50%);
$forminputbordercolorhover      : darken($forminputbordercolor, 10%);
$forminputbordercolorfocus      : darken($forminputbordercolor, 25%);
$forminputborderradius          : 0;
$formplaceholdercolor           : $lightneutralcolor;
