@mixin o-inline-list() {
    list-style: none;

    li {
        display: inline-block;
    }

}

.o-inline-list {
    @include o-inline-list;
}