.c-post {
    position: relative;
}

    .c-post--excerpt {

        .c-post__image {
            position: relative;
            line-height: 0;

            a {
                display: block;
                background: $black;
            }

            a:hover img {
                opacity: .3;
            }

            figcaption {
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {

                figcaption {
                    opacity: 1;
                }

            }

        }

        .c-post__title {
            margin-top: 0;
            margin-bottom: 0;

            &:after {
                display: block;
                content: "";
                height: 1px;
                width: 60px;
                background-color: $black;
                margin-top: $baselineheight;
            }

        }

        .c-post__content {
            padding: $baselineheight;

            @include breakpoint(large-screens) {
                padding: $baselineheight*2 $baselineheight*2 $baselineheight*2 $baselineheight*4;
            }

        }

        p {
            margin-bottom: 0;
            color: lighten($black, 50%);
        }

        .c-post__link {
            color: $black;
            @include font-size(14);

            &:after {
                display: inline-block;
                padding: $baselineheight/3;
                margin-left: $baselineheight/2;
                @include font-size(12);
                line-height: 1;
                content: "\2192";
                border-radius: 50%;
                background: $black;
                color: $white;
            }

        }

        @include breakpoint(medium-screens) {
            border-left: 1px solid lighten($black, 90%);
            border-bottom: 1px solid lighten($black, 90%);

            &:first-child {
                border-left: 0;
            }

        }

    }
