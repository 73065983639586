.c-footer {
    background-color: $black;
    color: $white;


    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

    }

}
