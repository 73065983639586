.o-constrained {
    margin: 0 auto;
    max-width: $maxwidth;
}

.o-constrained-wide {
    margin: 0 auto;
    max-width: $maxwidth*1.5;
}

.o-constrained-narrow {
    margin: 0 auto;
    max-width: $maxwidth/2;
}

.o-constrained-tight {
    max-width: 33em;
}
